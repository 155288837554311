import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Modal from 'AqsModal';
import ServiceStopForm from '../../NewServiceStops/Form/Index';
import RouteReminderModal from '../../RouteReminders/RecordsModal';
import QuickRepairModal from '../../NewServiceStops/Form/QuickRepairModal';

import { toast } from 'react-toastify';

const AccountServiceStopForm = (props) => {
    const [serviceStop, setServiceStop] = useState(props.service_stop || {});
    const [saving, setSaving] = useState(false);
    const [routeReminderModalError, setRouteReminderModalError] = useState(false);
    const [showRouteReminderModal, setShowRouteReminderModal] = useState(false);
    const modalEl = useRef(null);
    const quickRepairsModalEl = useRef(null);

    useEffect(() => {
        const addLink = $("a#add-service-stop");

        addLink.unbind("click");
        addLink.on("click", (e) => {
            e.preventDefault();

            if (props.company_settings.enable_service_stop_limiter) {
                if (moment(props.account.next_service_date) <= moment()) {
                    showModal();
                } else {
                    toast.warn(`Cannot add Service Stop until ${moment(props.account.next_service_date).tz(props.company.timezone).format("MM/DD/YYYY hh:mm A)")}`, {
                        position: toast.POSITION.TOP_CENTER
                    })
                }
            } else {
                showModal();
            }
        });
    });

    useEffect(() => {
        if (props.showModal) showModal();
    }, [props.showModal]);

    const showModal = () => {
        modalEl.current.show();
        allRouteRemindersValid();
    };

    const account = () => {
        const account = props.account;
        account.previous_readings = props.previous_readings;
        account.billing_enabled = props.billing_enabled;

        return account;
    };

    const saveServiceStop = (serviceStop) => {
        setSaving(true);
        const method = serviceStop.id ? "PUT" : "POST";
        let url = `/accounts/${props.account.id}/service_stops`
        if (serviceStop.id) url += `/${serviceStop.id}`;

        const image_ids = (serviceStop.images || []).map(i => i.id);
        serviceStop.image_ids = image_ids;

        $.ajax({
            method,
            url,
            contentType: "application/json",
            data: JSON.stringify({
                service_stop: serviceStop
            })
        }).done((serviceStop) => {
            const event = new CustomEvent("serviceStopAdded", { detail: serviceStop });
            window.dispatchEvent(event);

            modalEl.current.hide();
            toast.success("Successfully saved Service Stop!", {
                position: toast.POSITION.TOP_CENTER
            })

            if (props.saveCallback) props.saveCallback(serviceStop)
            setServiceStop(serviceStop)
        }).fail(() => {
            toast.error("Unable to save Service Stop", {
                position: toast.POSITION.TOP_CENTER
            });
        }).always(() => {
            setSaving(false);
        });
    };

    const updateServiceStop = (serviceStop) => {
        setServiceStop(prevServiceStop => ({ ...prevServiceStop, ...serviceStop }));
    };

    const currentRouteReminders = () => {
        return serviceStop.new_route_reminder_records || props.route_reminders;
    };

    const allRouteRemindersValid = () => {
        setRouteReminderModalError(false);
        const reminders = currentRouteReminders().map(reminder => {
            reminder.invalid = (reminder.remarks || "").replace(/ /g, "").length < 1;
            return reminder;
        });

        if (reminders.filter(r => r.invalid).length > 0) {
            setRouteReminderModalError(true);
            displayRouteReminderModal();
            return false
        }

        return true;
    };

    const displayRouteReminderModal = () => {
        setShowRouteReminderModal(false);
        setTimeout(() => {
            setShowRouteReminderModal(true);
        });
    };

    const updateRouteReminders = (new_route_reminder_records) => {
        setServiceStop({ ...serviceStop, new_route_reminder_records });
    };

    const showQuickRepairModal = () => {
        if (!props.company_settings.enable_quick_repairs) return false;

        quickRepairsModalEl.current.showModal();
    };

    const saveQuickRepair = (quick_repair) => {
        setServiceStop({ ...serviceStop, quick_repair });
    };

    return(
        <Modal
            id="service-stop-form-modal"
            ref={modalEl}
            large={true}
            headerText={`${serviceStop.id ? "Edit" : "Create"} Service Stop`}
        >
            <ServiceStopForm
                saveServiceStop={saveServiceStop}
                updateServiceStop={updateServiceStop}
                uniqueKey={props.account.id}
                saving={saving}
                account={account()}
                service_stop={serviceStop}
                chemical_units={props.company_settings.chemical_units}
                pool_conditions={props.pool_conditions}
                master_service_items={props.master_service_items}
                is_route_tech={false}
                timezone={props.company.timezone}
                base_plan={props.company.base_plan}
                quick_repairs_enabled={props.company_settings.enable_quick_repairs}
                chemical_readings={props.chemical_readings}
                addable_chemicals={props.addable_chemicals}
                default_tasks={props.default_tasks}
                allRouteRemindersValid={allRouteRemindersValid}
                showQuickRepairModal={showQuickRepairModal}
            />
            <RouteReminderModal
                showModal={showRouteReminderModal}
                routeReminders={currentRouteReminders()}
                error={routeReminderModalError}
                update={updateRouteReminders}
            />
            { props.company_settings.enable_quick_repairs &&
                <QuickRepairModal
                    ref={quickRepairsModalEl}
                    templates={props.repair_templates}
                    saveQuickRepair={saveQuickRepair}
                />
            }
        </Modal>
    )
};

AccountServiceStopForm.propTypes = {
    company: PropTypes.object.isRequired,
    company_settings: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    route_reminders: PropTypes.array,
    service_stop: PropTypes.object,
    pool_conditions: PropTypes.object.isRequired,
    master_service_items: PropTypes.array.isRequired,
    current_user: PropTypes.object.isRequired,
    chemical_readings: PropTypes.array.isRequired,
    addable_chemicals: PropTypes.array.isRequired,
    default_tasks: PropTypes.array.isRequired,
    repair_templates: PropTypes.array.isRequired,
    previous_readings: PropTypes.object.isRequired,
    billing_enabled: PropTypes.bool.isRequired,
    showModal: PropTypes.bool,
    saveCallback: PropTypes.func
}

export default props => <AccountServiceStopForm {...props} />;
