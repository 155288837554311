import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ImageArea from '@shared/components/ImageArea/ImageArea';
import ServiceSetShow from './ServiceSets/Show';
import RouteReminderRecordList from '../RouteReminders/RecordList';
import AccountServiceStopForm from '../Accounts/ServiceStops/Form';

import Utils from '../../Utils';

export default class ServiceStopShow extends React.Component {
    static propTypes = {
        service_stop: PropTypes.object.isRequired,
        display_links: PropTypes.bool.isRequired,
        chemical_readings: PropTypes.array.isRequired,
        addable_chemicals: PropTypes.array.isRequired,
        company_settings: PropTypes.object.isRequired,
        company: PropTypes.object.isRequired,
        current_user: PropTypes.object.isRequired,
        default_tasks: PropTypes.array.isRequired,
        tasks_completed: PropTypes.object.isRequired,
        pool_conditions: PropTypes.object,
        previous_readings: PropTypes.object,
        billing_enabled: PropTypes.bool
    }

    constructor(props) {
        super(props);

        this.baseState = {
            selectedBow: null,
            showEditServiceStopModal: false,
            serviceStop: props.service_stop
        }

        this.state = this.baseState;
    }

    bodyOfWaterButton = (bow) => {
        const klass = (this.state.selectedBow && this.state.selectedBow.id === bow.id) ? "btn-info" : "btn-default";

        return(
            <button
                className={`btn ${klass} margin-10-right`}
                onClick={(e) => this.setState({ selectedBow: bow })}
            >
                { bow.main &&
                    `${bow.name} (Main)`
                }
                { !bow.main &&
                    bow.name
                }
            </button>
        )
    }

    selectedServiceSet = () => {
        if (this.state.selectedBow.main) {
            return this.state.serviceStop
        } else {
            return this.state.serviceStop.service_sets.find(ss => ss.body_of_water_id === this.state.selectedBow.id);
        }
    };

    showEditServiceStopModal = () => {
        this.setState({ showEditServiceStopModal: false }, () => {
            this.setState({ showEditServiceStopModal: true });
        })
    };

    saveServiceStopCallback = (serviceStop) => {
        this.setState({ serviceStop });
    };

    render() {
        return(
            <div className="service-stop-show-wrapper">
                { this.props.display_links &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pull-right">
                                <a href={`/accounts/${this.props.service_stop.account_id}`} className="btn btn-default margin-5-right">
                                    <span className="glyphicon glyphicon-chevron-left margin-5-right"></span>
                                    Back to Account
                                </a>
                                <button className="btn btn-default" onClick={this.showEditServiceStopModal}>
                                    <i className="fa fa-pencil margin-5-right"></i>
                                    Edit Service Stop
                                </button>
                            </div>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-12">
                        <h1>
                            Service Stop Details
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h4>
                            <i className="fa fa-home margin-5-right"></i>
                            Address:
                            <span className="margin-5-left">
                                { this.props.service_stop.account.location.address }
                            </span>
                        </h4>
                    </div>
                </div>

                { this.state.serviceStop.in_time &&
                    <div className="row">
                        <div className="col-md-12">
                            <h5>
                                <i className="fa fa-clock-o margin-5-right color-gray"></i>
                                <span className="margin-5-right">
                                    In Time:
                                </span>
                                { moment(this.state.serviceStop.in_time).format("MM/DD/YYYY hh:mm A") }
                            </h5>
                        </div>
                    </div>
                }

                <div className="row">
                    <div className="col-md-12">
                        <h5>
                            <i className="fa fa-clock-o margin-5-right color-gray"></i>
                            <span className="margin-5-right">
                                Out Time:
                            </span>
                            { moment(this.state.serviceStop.out_time).format("MM/DD/YYYY hh:mm A") }
                        </h5>
                    </div>
                </div>
                { this.state.serviceStop.user_id &&
                    <div className="row">
                        <div className="col-md-12">
                            <h5>
                                <i className="fa fa-truck margin-5-right color-service-stop"></i>
                                <span className="margin-5-right">
                                    Serviced By:
                                </span>
                                { this.state.serviceStop.user.name }
                            </h5>
                        </div>
                    </div>
                }

                <hr/>

                <div className="row">
                    <div className="col-md-12">
                        <h4>
                            Images:
                        </h4>
                        <ImageArea
                            images={this.state.serviceStop.images}
                            resource_name="service_stop"
                            resource_id={this.state.serviceStop.id}
                            multiple_allowed={true}
                        />
                    </div>
                </div>

                <hr/>

                { this.state.serviceStop.service_sets.length > 0 &&
                    <Fragment>
                        <div className="row">
                            <div className="col-md-12 display-flex overflow-x-scroll">
                                {
                                    [this.state.serviceStop.account.main_bow, ...this.state.serviceStop.service_sets.map(ss => ss.body_of_water)].map((bow, index) => (
                                        <div key={index}>
                                            { this.bodyOfWaterButton(bow) }
                                        </div>

                                    ))
                                }
                            </div>
                        </div>
                        { this.state.selectedBow &&
                            <div className="margin-10-top">
                                <ServiceSetShow
                                    serviceSet={this.selectedServiceSet()}
                                    chemicalReadings={this.props.chemical_readings}
                                    addableChemicals={this.props.addable_chemicals}
                                    chemicalUnits={this.props.company_settings.chemical_units}
                                    isNotAdmin={!this.props.current_user.roles.includes("admin")}
                                    defaultTasks={this.props.default_tasks}
                                    tasksCompleted={this.props.tasks_completed}
                                />
                            </div>
                        }
                    </Fragment>
                }
                { this.state.serviceStop.service_sets.length < 1 &&
                    <div className="margin-10-top">
                        <ServiceSetShow
                            serviceSet={this.state.serviceStop}
                            chemicalReadings={this.props.chemical_readings}
                            addableChemicals={this.props.addable_chemicals}
                            chemicalUnits={this.props.company_settings.chemical_units}
                            isNotAdmin={!this.props.current_user.roles.includes("admin")}
                            defaultTasks={this.props.default_tasks}
                            tasksCompleted={this.props.tasks_completed}
                        />
                    </div>
                }

                { this.state.serviceStop.route_reminder_records.length > 0 &&
                    <Fragment>
                        <hr/>

                        <div className="row">
                            <div className="col-md-12">
                                <h4>
                                    Reminder(s):
                                </h4>
                                <RouteReminderRecordList records={this.state.serviceStop.route_reminder_records} />
                            </div>
                        </div>
                    </Fragment>
                }

                { this.props.current_user.roles.includes("admin") && (this.props.service_stop.account.billing_period_type === "weekly" || this.props.service_stop.account.billing_period_type === "4 weeks" || this.props.service_stop.account.rate_type === "per_stop") &&
                    <Fragment>
                        <hr/>

                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="no-margin">
                                    Total Service Stop Cost: { Utils.floatToCurrency(parseFloat(this.state.serviceStop.grand_total)) }
                                </h4>
                            </div>
                        </div>
                    </Fragment>
                }
                { this.props.display_links &&
                    <AccountServiceStopForm
                        company={this.props.company}
                        company_settings={this.props.company_settings}
                        account={this.props.service_stop.account}
                        route_reminders={this.props.service_stop.account.active_scheduled_route_reminders}
                        service_stop={this.state.serviceStop}
                        pool_conditions={this.props.pool_conditions}
                        master_service_items={this.props.company.master_service_items}
                        current_user={this.props.current_user}
                        chemical_readings={this.props.chemical_readings}
                        addable_chemicals={this.props.addable_chemicals}
                        default_tasks={this.props.default_tasks}
                        repair_templates={this.props.company.repair_templates}
                        previous_readings={this.props.previous_readings}
                        billing_enabled={this.props.billing_enabled}
                        showModal={this.state.showEditServiceStopModal}
                        saveCallback={this.saveServiceStopCallback}
                    />
                }
            </div>
        )
    }
}
